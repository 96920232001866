import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { authReducer } from "./reducer/authReducer";
import { dashboardReducer } from "./reducer/dashboardReducer";
import tablesReducer from "./reducer/tableReducer";
import { userProfileReducer } from "./reducer/userProfileReducer";



const appReducer = combineReducers({
  authentication: authReducer,
  userInfo: userProfileReducer,
  dashboard: dashboardReducer,
  tables: tablesReducer,
});

export const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
