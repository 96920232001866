import axiosInstance from "../utils/axios";

export function CallWithOutAuth(method, url, body = {}) {
  return new Promise((resolve, reject) => {
    try {
      if (method === "POST") {
        axiosInstance
          .post(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PUT") {
        axiosInstance
          .put(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PATCH") {
        axiosInstance
          .patch(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "GET") {
        axiosInstance
          .get(url)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      // console.log("Catch block error in CallWithOutAuth method");
      // console.log(err.response);
      reject(err.response);
    }
  });
}

export function CallWithAuth(method, url, body = {}) {
  const header = {
    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  };

  return new Promise((resolve, reject) => {
    try {
      if (method === "POST") {
        axiosInstance
          .post(url, body)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PUT") {
        axiosInstance
          .put(url, body)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PATCH") {
        axiosInstance
          .patch(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "GET") {
        axiosInstance
          .get(url, header)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              // console.log(response,"...ERROR 44444...")
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "DELETE") {
        axiosInstance
          .delete(url, body)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      // console.log("Catch block error in CallWithAuth method");
      // console.log(err);
      reject(err.response);
    }
  });
}

export function CallWithAuthFormData(method, url, body = {}) {
  const headercustom = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  // Print fromData
  // for (let pair of body.entries()) {
    // console.log(pair[0]+ ', ' + pair[1]);
  // }

  return new Promise((resolve, reject) => {
    try {
      if (method === "POST") {
        axiosInstance
          .post(url, body, headercustom)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PUT") {
        axiosInstance
          .put(url, body, headercustom)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      // console.log("Catch block error in CallWithAuthFormData method");
      // console.log(err);
      reject(err.response);
    }
  });
}
