const intialState = {
  error: null,
  dashboardData: [],
  recentVisted: [],
  // -----------watch all page---
  subCategories: [],
  isLodingWatchAll: false,

  selectedForum: [],
  othersForums: [],
  attending: [],
  search: {
    searchResponse: [],
    loading: false,
  },
  loading: false,
  loadingVisitForam: false,
  isLodingAttending: false,
};

export const dashboardReducer = (state = intialState, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "DASHBOARD_DATA_FETCH_SUCCESS":
      return {
        ...state,
        dashboardData: action.data,
        loading: false,
        error: null,
      };
    case "RECENT_VISITED_DATA_FETCH_SUCCESS":
      return {
        ...state,
        recentVisted: action.data,
        // loading: false,
        // error: null,
      };
    case "START_LOADING_WATCH_ALL":
      return {
        ...state,
        isLodingWatchAll: true,
      };
    case "FETCH_ALL_SUB_CATEGORIES":
      return {
        ...state,
        subCategories: action.data,
        isLodingWatchAll: false,
        error: null,
      };
    case "FETCH_ALL_DATA_OF_OTHERS":
      return {
        ...state,
        othersForums: action.data,
        loading: false,
        error: null,
      };


    case "WATCH_A_SINGLE_FORA":
      return {
        ...state,
        selectedForum: action.data,
        loadingVisitForam: false,
        error: null,
      };

    case "DASHBOARD_DATA_FETCH_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "START_SEARCH_LOADING":
      return {
        ...state,
        search: {
          loading: true,
          searchResponse: [],
          // searchResponse: state.search.searchResponse
        },
      };
    case "FETCH_SEARCH_DATA":
      return {
        ...state,
        search: {
          loading: false,
          searchResponse: action.data,
        },
      };
    case "FETCH_SEARCH_DATA_FAIL":
      return {
        ...state,
        search: {
          loading: false,
          searchResponse: [],
        },
      };
    case "SET_ATTENDING_DATA":
      return {
        ...state,
        attending: action.data,
        isLodingAttending: false,
        error: null,
      };
    case "START_LOADING_ATTENDING_FORAM":
      return {
        ...state,
        isLodingAttending: true,
        error: null,
      };
    
    case "START_LOADING_VISIT_A_FORAM":
    return {
      ...state,
      loadingVisitForam: true,
    };
    default:
      return state;
  }
};