// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA6YLtMbKB34I0SHalCdFp8dczQsJXUqws",
  authDomain: "see-surround.firebaseapp.com",
  databaseURL: "https://see-surround.firebaseio.com",
  projectId: "see-surround",
  storageBucket: "see-surround.appspot.com",
  messagingSenderId: "572316292080",
  appId: "1:572316292080:web:43db02d09e5846590b8335",
  measurementId: "G-1BZ4WNJRSZ"
}; 

// Initialize Firebase
 const app = initializeApp(firebaseConfig);
 const analytics = getAnalytics(app);
 const auth = getAuth(app);
const firebaseDB = getDatabase(app);
// export const database = getDatabase(app);
 const storage = getStorage(app);
export { app, auth, firebaseDB,storage };

