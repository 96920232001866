const intialState = {
  error: null,
  categories: [],
  loading: false,

  userByid: [],
  isLodingUBI: false,
  // -----------------ROUND TABLE
  loadingCreateTable: false,

};

export const userProfileReducer = (state = intialState, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "CATEGORY_SUCCESS":
      return {
        ...state,
        categories: action.data,
        loading: false,
        error: null,
      };
    case "START_LOADING_USER_BY_ID":
      return {
        ...state,
        isLodingUBI: true,
      };

    case "USER_BY_ID":
      return {
        ...state,
        userByid: action.data,
        isLodingUBI: false,
        error: null,
      };

    case "CATEGORY_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case "START_CREATING_ROUNDTABLE":
      return {
        ...state,
        loadingCreateTable: true,
      };
    case "END_CREATING_ROUNDTABLE":
    return {
      ...state,
      loadingCreateTable: false,
    };
    default:
      return state;
  }
};