import { ref, child, get, set } from "@firebase/database";
import { firebaseDB } from "../firebase";

export const createUserInDB = async ({
  displayName,
  email,
  phoneNumber,
  photoURL,
  uid,
}) => {
  const dbref = ref(firebaseDB, `users/${uid}`);
  const userPayload = {
    displayName,
    email,
    phoneNumber,
    photoURL,
    uid,
  };
  await set(dbref, userPayload);
};

export const createUserIfNotExists = async (userData) => {
  // check if user exist in realtime db
  // if not exist, then call createUserInDB(userData) method
  const { uid } = userData;
  const dbref = ref(firebaseDB);
  const snapshot = await get(child(dbref, `users/${uid}`));
  console.log(":: USER EXIST ::", snapshot.exists(), uid);
  if (!snapshot.exists()) {
    console.log(!snapshot);
    await createUserInDB(userData);
  }
};
