import React, { createContext, useState, useEffect, lazy } from "react";

import jwt_decode from "jwt-decode";
import axiosInstance from "utils/axios";
import { CallWithAuth } from "action/apiActions";
import { GET_MY_PROFILE, GET_USER_BY_ID } from "action/apiPath";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { createUserIfNotExists } from "services/user.services";
import { useDispatch } from "react-redux";
import { fetchAllDashboardData } from "store/action/dashboardAction";

const FullPageLoader = lazy(() => import("components/FullPageLoader"));
const AuthContext = createContext({});
const dashboardDefault = `wantDashboardData`;
const wantVisitedData = `wantVisitedData`;

export const AuthProvider = ({ children }) => {
  
  const dispatch = useDispatch()
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    isInitialized: true,
    roles: [],
    userData: {},
  });

  const [isAllDataField, setIsAllDataField] = useState({
    loading: true,
    status: false,
    data: null,
  });

  const logout = (showMsg = true) => {
    delete axiosInstance.defaults.headers.common.Authorization;
    localStorage.clear();
    setAuth({
      isAuthenticated: false,
      isInitialized: false,
      roles: "",
      userData: {},
    });
    signOut(auth);
  };
  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    // const decoded = jwt_decode(accessToken);
    // const currentTime = Date.now() / 1000;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // console.log("decoded:---", decoded)
    // console.log("currentTime:--", Math.ceil(currentTime))

    // return decoded.exp > Math.ceil(currentTime);
    return true;
  };

  const checkIfAllDataFilled = async () => {
    try {
      const { status, res } = await CallWithAuth("POST", GET_USER_BY_ID, {data: {}});
      // remove leter
      if (res.status === 401) {
        localStorage.removeItem("accessToken");
      }
      if (status) {
        setIsAllDataField((prev) => ({
          ...prev,
          data: res?.data?.result?.data,
        }));
        const validation = [
          // res?.data?.email_verified,
          // res?.data?.followersList.length > 0,
          res?.data?.followingList?.length > 0,
          Boolean(res?.data?.name),
          Boolean(res?.data?.dob),
          Boolean(res?.data?.gender),
        ];
        if (validation.every((elem) => elem === true)) {
          setIsAllDataField((prev) => ({
            ...prev,
            loading: false,
            status: true,
          }));
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
    setIsAllDataField((prev) => ({
      ...prev,
      loading: false,
      status: false,
    }));
  };

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      isValidToken(localStorage.getItem("accessToken"))
    ) {
      // CallWithAuth("GET", GET_MY_PROFILE).then((userDetailsResponse) => {
      // if(userDetailsResponse.status && userDetailsResponse.res.data.success){

      setAuth({
        isAuthenticated: true,
        isInitialized: false,
        userData: {},
        roles: [],
      });

      // }else{
      //   logout(false);
      // }
      //   })
    } else {
      setAuth({
        isAuthenticated: false,
        isInitialized: false,
        roles: "",
        userData: {},
      });
    }
  }, []);

  const onAuthChange = async (auth) => {
    if (auth?.userData?.email) {
      await createUserIfNotExists(auth?.userData);
    }
    if (
      localStorage.getItem("accessToken") &&
      isValidToken(localStorage.getItem("accessToken"))
    ) {
      dispatch(fetchAllDashboardData(dashboardDefault));
    dispatch(fetchAllDashboardData(wantVisitedData));
      // console.log("inside");
      checkIfAllDataFilled();
    }
  };

  useEffect(() => {
    onAuthChange(auth);
  }, [auth]);

  if (auth.isInitialized) {
    return <FullPageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        setIsAllDataField,
        isAllDataField,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

 