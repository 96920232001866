

const BASE_PATH = "/admin";
// const BASE_USER_PATH = "/see-surround/us-central1";

/**
 * //////////////////////////////////////////////////
 * GET routes
 * /////////////////////////////////////////////////
 */
export const GET_MY_PROFILE = `${BASE_PATH}/my-profile`; //GET
// export const GET_ALL_CATEGORIES = `/getCategories`;
// export const GET_USER_BY_ID = `/getUserById`;
// export const FOLLOW_CATEGORY = `/toggleFollower`;
// export const UPDATE_USER_DETAILS = `/updateUser`;
// export const GET_DASHBOARD_DATA = `/getForum`;
// export const VISIT_A_FORA = `/getForaById`;
// export const SEARCH_FORUMS_OR_USERS = `/search`;


export const GET_ALL_CATEGORIES = `/getCategoriesWeb`;
export const GET_USER_BY_ID = `/getUserByIdWeb`;
export const FOLLOW_CATEGORY = `/toggleFollowerWeb`;
export const UPDATE_USER_DETAILS = `/updateUserWeb`;
export const GET_DASHBOARD_DATA = `/getForumWeb`;
export const VISIT_A_FORA = `/getForaByIdWeb`;
export const SEARCH_FORUMS_OR_USERS = `/searchWeb`;
export const PLAN_TO_ATTEND = `/updateSubscriptionWeb`;
export const GET_ALL_ATTENDING = `/getAttendingWeb`;
export const CREATE_ROUND_TABLE = `/createForaV2Web`;
export const JOIN_FOURM_WEB=`/joinForumWeb`;

/**
 * ////////////////////////////////////////////////////
 * POST routes
 * ///////////////////////////////////////////////////
 */

export const LOGIN_API_URL = `${BASE_PATH}/login`; //POST
