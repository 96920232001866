
import { CallWithAuth } from "action/apiActions";
import { GET_ALL_ATTENDING, GET_DASHBOARD_DATA, PLAN_TO_ATTEND, SEARCH_FORUMS_OR_USERS, VISIT_A_FORA } from "action/apiPath";
import { getCategorySponsoredUserCombined, septateEachCategoriesForums } from "utils/utils";

export const fetchLoadingStart = () => {
  return {
    type: "START_LOADING"
  };
};

export const lodingWatchAllCategories = () => {
  return {
    type: "START_LOADING_WATCH_ALL"
  };
};

export const searchLoadingStart = () => {
  return {
    type: "START_SEARCH_LOADING"
  };
};

export const fetchDashboardDataDone = data => {
  return {
    type: "DASHBOARD_DATA_FETCH_SUCCESS",
    data,
  };
};

export const fetchRecentVisitedDataDone = data => {
  return {
    type: "RECENT_VISITED_DATA_FETCH_SUCCESS",
    data,
  };
};

export const fetchAllSubCategoriesOfCategory = data => {
  return {
    type: "FETCH_ALL_SUB_CATEGORIES",
    data,
  };
};

export const fetchAllDataOfOthers = data => {
  return {
    type: "FETCH_ALL_DATA_OF_OTHERS",
    data,
  };
};

export const watchAForaUsingForaId = data => {
  return {
    type: "WATCH_A_SINGLE_FORA",
    data,
  };
};

export const fetchDashboardDataFailed = error => {
  return {
    type: "DASHBOARD_DATA_FETCH_FAILED",
    error,
  };
};

export const fetchSearchData = data => {
  return {
    type: "FETCH_SEARCH_DATA",
    data,
  };
};

export const setAllAttendingData = data => {
  return {
    type: "SET_ATTENDING_DATA",
    data,
  };
};

export const fetchFailSearchData = () => {
  return {
    type: "FETCH_SEARCH_DATA_FAIL",
  };
};

export const loadingStartVisitAForam = () => {
  return {
    type: "START_LOADING_VISIT_A_FORAM"
  };
};

export const loadingStartAttendingForum = () => {
  return {
    type: "START_LOADING_ATTENDING_FORAM"
  };
};

export const fetchAllDashboardData = (data, cat) => {
  return dispatch => {
    // dispatch(fetchLoadingStart());
    const visitedForum = ["visited_forum"]
    const categoryName = [cat] || []
    const payloadVisitedFora = { category: data === "wantVisitedData" ? visitedForum : categoryName }
    // ----------------Dashboard default data-------------------
    if (data === 'wantDashboardData') {
      dispatch(fetchLoadingStart());
      CallWithAuth("POST", GET_DASHBOARD_DATA, { data: {} })
        .then(response => {
          if (response?.res?.data?.result?.data) {
            const res = getCategorySponsoredUserCombined(response?.res?.data?.result?.data)
            dispatch(fetchDashboardDataDone(res));
          }
        })
        .catch(error => {
          dispatch(fetchDashboardDataFailed("somthing wents wrong"));
        });
    }
    // ----------------Recent Viwed data-------------------
    if (data === "wantVisitedData") {
      CallWithAuth("POST", GET_DASHBOARD_DATA, { data: payloadVisitedFora })
        .then(response => {
          if (response?.res?.data?.result?.data) {
            dispatch(fetchRecentVisitedDataDone(response?.res?.data?.result?.data[0]?.forum))
          }
        })
        .catch(error => {
          // dispatch(fetchDashboardDataFailed("somthing wents wrong"));
        });
    }

    // ----------------See All button data-------------------
    if (data === 'wantForaByCategory') {
      dispatch(lodingWatchAllCategories());
      CallWithAuth("POST", GET_DASHBOARD_DATA, { data: payloadVisitedFora })
        .then(response => {
          if (response?.res?.data?.result?.data) {
            dispatch(fetchAllSubCategoriesOfCategory(response?.res?.data?.result?.data))
          }
        })
        .catch(error => {
          // dispatch(fetchDashboardDataFailed("somthing wents wrong"));
        });
    }

    // ----------------Others data related to forums-------------------
    if (data === 'wantDataOfOthers') {
      dispatch(fetchLoadingStart());
      CallWithAuth("POST", GET_DASHBOARD_DATA, { data: cat })
        .then(response => {
          console.log(response, { data: cat }, "***********")
          if (response?.res?.data?.result) {
            const res = septateEachCategoriesForums(response?.res?.data?.result?.data)
            dispatch(fetchAllDataOfOthers(res))
          }
        })
        .catch(error => {
          // dispatch(fetchDashboardDataFailed("somthing wents wrong"));
        });
    }
  };
};


export const visitAfora = (params, navigate) => {
  return dispatch => {
    dispatch(loadingStartVisitAForam());
    // navigate(`/dashboard/details/sub-category/${params.fora_id}`);
    // let query = `?`;
    // for (var i in params) {
    //   query += `&${i}=${params[i]}`;
    // }

    CallWithAuth("POST", `${VISIT_A_FORA}`, { data: params })
      .then(response => {
        if (response?.res?.status) {
          dispatch(watchAForaUsingForaId(response?.res?.data?.result?.data));
          params?.record_visit && dispatch(fetchAllDashboardData('wantVisitedData'));
          navigate(`/dashboard/details/sub-category/${params.fora_id}`);
        }
      })
      .catch(error => {
        // dispatch(fetchDashboardDataFailed("somthing wents wrong"));
      });
  };
};


export const searchForumsOrUser = (params) => {
  return dispatch => {
    const blank = '\u00a0\u00a0\u00a0\u00a0'
    dispatch(searchLoadingStart());
    let query = `?`;
    for (var i in params) {
      query += `&${i}=${params[i]}`;
    }
    CallWithAuth("POST", `${SEARCH_FORUMS_OR_USERS}`, { data: params })
      .then(response => {
        if (response?.status) {
          dispatch(fetchSearchData(response?.res?.data?.result));
        } else {
          dispatch(fetchFailSearchData(response?.res?.data?.result));
        }
      })
      .catch(error => {
        // dispatch(fetchDashboardDataFailed("somthing wents wrong"));
      });
  };
};


export const planToAttend = (params) => {
  return dispatch => {
    // dispatch(fetchLoadingStart())
    CallWithAuth("POST", PLAN_TO_ATTEND, { data: params })
      .then(response => {
        dispatch(visitAfora({ fora_id: params.fora_id, record_visit: false }))
        // dispatch(fetchUserByIdDone(response?.res?.data?.result?.data));
      })
      .catch(error => {
        // dispatch(fetchCategoriesFailed("somthing wents wrong"));
      });
  };
};

export const getAllAttending = (params) => {
  return dispatch => {
    dispatch(loadingStartAttendingForum())
    CallWithAuth("POST", GET_ALL_ATTENDING, { data: {} })
      .then(response => {
        dispatch(setAllAttendingData(response?.res?.data?.result?.data))
      })
      .catch(error => {
        // dispatch(fetchCategoriesFailed("somthing wents wrong"));
      });
  };
};